import './PopiaPwReset.scss';

import React, { useState, useContext, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router';

import { AuthTokenContext, UserContext } from '../../context';
import { get, post } from '../../api';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CloudIcon from '@material-ui/icons/Cloud';
import StorageIcon from '@material-ui/icons/Storage';
import LoginIcon from '@mui/icons-material/Login';
import PasswordChecklist from "react-password-checklist";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { red } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    heading: {
        color: 'red',
        fontSize: '14px',
    },
    disabledButton: {
        color: 'red',
      },
}));

const PopiaPwReset = () => {
    const classes = useStyles();
    const { user } = useContext(UserContext);
    const { isTokenValid } = useContext(AuthTokenContext);
    const [pass, setPass] = useState('');
    const [confirmPass, setConfirmPass] = useState();
    const [cloudAccess, setCloudAccessList] = useState([]);
    const [dbAccess, setDbAccessList] = useState([]);
    const [sshAccess, setSshAccessList] = useState([]);
    const [error, setError] = useState('');
    const [lastPassUpdated, setLastPassUpdated] = useState([]);
    const [failedPassCount, setfailedPassCount] = useState([]);
    const [passwordStandard, setPasswordStandard] = useState(false);

    const UpdatePassword = async () => {
        if (pass !== confirmPass) {
            setError("Password and confirm password does not match");
        } else {
            setError("");
        }
        if (pass === confirmPass) {
            const isNewPassword = await post('/popia/poapia_password_validate', {
                partner_id: user.id,
                new_password: pass,
            });
            if(isNewPassword.status === 201){
                const resp = await post('/popia/popia_pw_reset', {
                    partner_id: user.id,
                    new_password: pass,
                });

                if (resp.ok) {
                    setError("Password has been succesfully reset");
                    toast.success("Password has been succesfully reset");
                } else {
                    setError("Unable to reset your password due to server error");
                    toast.error("Unable to reset your password due to server error");
                }
                return resp.ok;
            } else if(isNewPassword.status === 202) {
                setError("Cannot reuse previous password")
                toast.error("Cannot reuse any of 3 previous passwords")
            }
      }
    };

    const ResetPasswordCount = async () => {
        const resp = await post('/popia/popia_pw_reset_login_count', {
            partner_id: user.id,
        });
        if (resp.ok) {
            toast.success("Password login has been reset to zero");
        } else {
            toast.error("Password login has been not been reset");
        }
        return resp.ok
    };

    const validateFields = () => {
        if (pass === "" ) {
            setError("Please enter a password");
            toast("Please enter a password");
        } else {
            setError("");
        }
        if (pass.length < 12) {
            setError("Password must be a minimum of 12 characters");
            toast.warn("Password must be a minimum of 14 characters");
        } else {
            setError("");
        }
        if(passwordStandard === false) {
            setError("Password does not meet security standards");
            toast.warn("Password does not meet security standards");
        }else if(passwordStandard === true) {
            setError("");
        }
    };

    useEffect(() => {
        if (user !== null) {
        const clouds = async () => {
          const resp = await get(`/popia/employee_cloud_access/${user.id}`);

          if (resp.ok) {
            setCloudAccessList(await resp.json());
          }
        };

        clouds();
    }
    }, [setCloudAccessList, user]);

    useEffect(() => {
        if (user !== null) {
        const dbs = async () => {
          const resp = await get(`/popia/employee_db_access/${user.id}`);

          if (resp.ok) {
            setDbAccessList(await resp.json());
          }
        };

        dbs();
    }
    }, [setDbAccessList, user]);
    
    
    useEffect(() => {
        if (user !== null) {
        const sshs = async () => {
          const resp = await get(`/popia/employee_ssh_access/${user.id}`);

          if (resp.ok) {
            setSshAccessList(await resp.json());
          }
        };

        sshs();
    }
    }, [setSshAccessList, user]);    
    

    useEffect(() => {
        if (user !== null) {
        const get_employee = async () => {
          const resp = await get(`/fetch_employee/${user.id}`);

          if (resp.ok) {
            setLastPassUpdated(await resp.json());
          }
        };

        get_employee();
    }
    }, [setLastPassUpdated, user]);

    useEffect(() => {
        if (user !== null) {
        const get_employee_failed_count = async () => {
          const resp = await get(`/popia/employee_failed_count/${user.id}`);

          if (resp.ok) {
            setfailedPassCount(await resp.json());
            console.log(resp)
          }
        };
        get_employee_failed_count();
    }
    }, [setfailedPassCount, user]);


    return (
        isTokenValid() ? (
        <>
        <ToastContainer position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover/>
        <div className="Container">
            <br />
            <h3>Reset your POPIA Password</h3>
            <h4 className={classes.heading}>Your password will be used for Cloud, Cluster, Desktop and GXWeb access.</h4>
            <input
                type="text"
                className="InputPwd"
                placeholder="Enter a new password"
                onChange={(e) => setPass(e.target.value)}
                onBlur={validateFields}
                style={{ marginBottom: '5px' }}
            />
            <br />
            <input
                type="text"
                className="InputPwd"
                placeholder="Confirm your new password"
                onChange={(e) => setConfirmPass(e.target.value)}
                onBlur={validateFields}
            />
            <br />
            <div className="passwordErrors">
                <PasswordChecklist
                    rules={["minLength","match"]}
                    minLength={14}
                    value={pass}
                    valueAgain={confirmPass}
                    onChange={(isValid) => {setPasswordStandard(isValid)}}
                />
            </div>
            <br />
            <button type="submit" onClick={UpdatePassword} disabled={error} >
                Reset Password
            </button>
            <br />
            <br />
            {lastPassUpdated ?
            <>
                <span className="ErrMsg">Last Password Reset: </span>
                <span className="ErrMsg">{lastPassUpdated}</span>
            </>
            :null}
            <br />
            {failedPassCount ?
            <>
                <span>Failed Attempts Count: </span>
                <span>{failedPassCount}</span>
            </>
            :null}
            <br />
            <button type="submit" onClick={ResetPasswordCount} disabled={failedPassCount<5} >
                Reset Password Login Count
            </button>
            <br />
            <p style={{marginLeft: '43%'}}>Access will be given to the list below:</p>
            <fieldset className="GroupList">
            <legend className="GroupListLbl">Cloud Access</legend>
            <div className={classes.root}>
            {cloudAccess.map((cloud) =>
            <Chip key={cloud.id}
                icon={<CloudIcon />}
                label={cloud.cloud_name}
                clickable
                color="primary"
            />
            )}
            </div>
            </fieldset>
            <br />
            <fieldset className="GroupList">
            <legend className="GroupListLbl">Cluster Access</legend>
            <div className={classes.root}>
            {dbAccess.map((db) =>
            <Chip
                key={db.id}
                icon={<StorageIcon />}
                label={db.cluster_name}
                clickable
                color="primary"
            />
            )}
            </div>
            </fieldset>
            <br />
            <fieldset className="GroupList">
            <legend className="GroupListLbl">Server SSH Access</legend>
            <div className={classes.root}>
            {sshAccess.map((ssh) =>
            <Chip
                key={ssh.id}
                icon={<LoginIcon />}
                label={ssh.hostname}
                clickable
                color="primary"
            />
            )}
            </div>
            </fieldset>            
        </div>
        </>
        ): <Redirect to="/login?returl=popia-pw-reset" />
    );
};

export default withRouter(PopiaPwReset);
