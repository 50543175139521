import './Issue.scss';

import React, { useContext, useEffect, useState } from 'react';

import { get, post, fetchData } from '../../api';
import NewMessage from '../../components/message/Message';
import { UserContext } from '../../context';
import { priorityAltText, priorityLevel, dateDisplay } from '../home/components/IssueList';
import uploadAttachments from '../uploadAttachments';
import Attachments from './Attachments';
import Messages from './Message';
import Tooltip from '@material-ui/core/Tooltip';
import PrevEscalations from './PrevEscalations';

import DesktopLayout  from '../../components/utils/DesktopLayout';
import MobileLayout  from '../../components/utils/MobileLayout';
import responsive from '../../responsive.module.scss';

const fetchMessages = async ({ issueId, setMessages }) => {
  const resp = await get(`/issue/${issueId}/messages`);

  if (resp.ok) {
    setMessages(await resp.json());
  }

  return resp.ok;
};

const Issue = ({ match, history }) => {
  const { user } = useContext(UserContext);

  const [issue, setIssue] = useState(null);
  const [messages, setMessages] = useState([]);
  const [reasonCat, setReasonCat] = useState("");
  const [reasonDesc, setReasonDesc] = useState("");
  const [escalateErrMsg, setEscalateErrMsg] = useState("");
  const [escalateSuccessMsg, setEscalateSuccessMsg] = useState("");

  const [config, setConfig] = useState([]);
  const [escalations, setEscalations] = useState([]);
  const [showEscalatePopup, setShowEscalatePopup] = useState(false);
  const [escalateStatus, setEscalateStatus] = useState(false);

  const [isRead, setIsRead] = useState(false);
  const issueId = match.params.id;
  const [previousEscalations, setPreviousEscalations] = useState([]);

  const GoToHome = () => history.push({ pathname: `/` });

  // const PopupClose = () => {
  //   history.push(history.location.pathname);
  //   setShowEscalatePopup(false);
  // };

  const PopupOpen = () => {
    setEscalateStatus(false);
    setEscalateErrMsg("");
    setEscalateSuccessMsg("");
    setReasonCat("");
    setReasonDesc("");
    setEscalations(escalations);

    if (user !== null) {
      fetchData(`/get_available_escalations/${user.partner_id}`, setEscalations);
    }

    setShowEscalatePopup(true);
  };

  const newMessageSubmit = async (message, files) => {
    const posspanata = {
      body: message,
      email_from: `${user.name} <${user.email}>`,
      user_id: user.id,
      attachment_ids: await uploadAttachments(issue.id, issue.name, files),
    };

    const resp = await post(`/issue/${issueId}/message`, posspanata);

    if (resp.ok) {
      await fetchMessages({ issueId, setMessages });
    }

    return resp.ok;
  };

  const escalateIssue = async () => {
    setEscalateErrMsg("");
    setEscalateSuccessMsg("");
    const resp = await post('/escalate_issue', {
      reasonCat,
      user,
      issue,
      reasonDesc,
    });

    if (resp.ok && reasonCat !== "") {
      setEscalateSuccessMsg(<p>Your issue has been successfully escalated. <br /> We will be in touch with you soon.</p>);
      setEscalateStatus(true);
      await escalateToStage();
      fetchData(`/get_available_escalations/${user.partner_id}`, setEscalations);
      setReasonCat("");
      // PopupClose();
    }
    else {
      if (reasonCat === "") {
        setEscalateErrMsg("Please select a reason to continue.");
      }
      let _resp = await resp.json();
      setEscalateErrMsg(_resp);
      return _resp;
    }
  };

  const escalateToStage = async () => {
    const resp = await post('/escalate_stage', {issueId});

    if (resp.ok) {
      setEscalateSuccessMsg(<p>Your issue has been successfully escalated. <br /> We will be in touch with you soon.</p>);
    } else {
      setEscalateErrMsg("Unable to escalate your issue. Please contact us (help@goodx.co.za) for more information.", resp);
    }
    return resp.ok;
  };

  useEffect(() => {
    fetchData(`/issue/${issueId}`, setIssue);
    if (user !== null) {
      fetchData(`/get_previous_escalations/${user.partner_id}`, setPreviousEscalations);
    }
    fetchData(`/odooConfig`, setConfig);
    fetchMessages({ issueId, setMessages });
  }, [issueId, user]);

  useEffect(() => {
    const timer = setTimeout(async() => {
      const resp = await post('/update_notifications', { user, issueId });

      if (resp.ok) {
        setIsRead(await resp.json());
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [issueId, isRead, user]);


  return issue === null ? (
    <h1>Sorry, you are unauthorised to view this page.</h1>
  ) : (
  <>
    <DesktopLayout>
    <div className="BackRow">
      <button className="TertiaryBtn" onClick={GoToHome}>
        <i className="material-icons">arrow_back</i> Back to open tickets
      </button>
    </div>

    <div className="PageWrapper">
      <div className="OverviewPanel">
        <div className="OverviewFirstRow">
          <p>[{issue.id}]</p>
          <div className="Priority">{priorityAltText(issue.priority)}&nbsp;&nbsp;
            <div className="PriorityBack">
              <div className={["PriorityFront", priorityLevel(issue.priority).split("_")[1]].join(' ')}>&nbsp;</div>
            </div>
          </div>
        </div>

        <h1>{issue.name}</h1>

        <div>
          <a href="#escalateModal">
          {config ?
          <button className="EscalateBtn" onClick={PopupOpen}>
            <i className="material-icons">file_upload</i> Escalate Issue
          </button>
          :null
          }
          </a>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">outlined_flag</i>
          <span className="field">Status:</span>
          <span className="value">{issue.project_type}</span>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">folder_open</i>
          <span className="field">Project:</span>
          <span className="value">
            {issue.project_description ?
              <Tooltip title={issue.project_description}>
                <span>{issue.project_name}</span>
              </Tooltip>
              : <span>{issue.project_name}</span>
            }
          </span>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">history</i>
          <span className="field">Last action:</span>
          <span className="value">{dateDisplay(issue.write_date)} (
          {new Date(issue.write_date).toLocaleString('en-GB')})</span>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">support_agent</i>
          <span className="field">FCL:</span>
          <span className="value">{issue.fcl_ref}</span>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">assignment_ind</i>
          <span className="field">Assigned to:</span>
          <span className="value">{issue.assigned_to ? issue.assigned_to : 'T.B.A.'}</span>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">code</i>
          <span className="field">Development Stage:</span>
          <span className="value">
            {issue.stage_description ?
            <Tooltip title={issue.stage_description}>
              <span>{issue.project_type}</span>
            </Tooltip>
            : 'T.B.A.'
            }
          </span>
        </div>


        <div className="OverviewRow">
          <i className="material-icons">schedule_send</i>
          <span className="field">Expected release date:</span>
          <span className="value">
          {issue.sprint ?
            <span>{issue.sprint}</span>
          : 'T.B.A.' }
          </span>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">event</i>
          <div className="field">Created date:</div>
          <div className="value">{dateDisplay(issue.create_date)} (
          {new Date(issue.create_date).toLocaleString('en-GB')})</div>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">edit</i>
          <div className="field">Created by:</div>
          <div className="value">{issue.logger_name}</div>
        </div>

        <br />
        {previousEscalations ?
          <PrevEscalations escalateObj={previousEscalations} />
        : null 
        }
      </div>

      <div className="DetailsPanel">
        <div className="DetailsFirstRow">
          <div>
            <h2>Description</h2>
            <p dangerouslySetInnerHTML={{ __html: issue.description }}></p>
          </div>
          <div>
            <h2>Steps To Reproduce</h2>
            <span>{ issue.diagnosis }</span>
          </div>
          <div>
            <h2>Attachments</h2>
            { issue.attachments.length === 0 ? (
              <p className="italic">No attachments</p>
            ) : (
              <Attachments attachments={issue.attachments} />
            )}
          </div>
          {issue.closed ? (
          <>
            {issue.resolution ?
            <div>
              <h2>Resolution</h2>
              <p>{ issue.resolution }</p>
            </div>:
            <></>
            }</>
          ):(<></>)}
        </div>

        <div className="DetailsRow">
          <h2>Messages</h2>
          <div>
            {issue.closed ? (
              <p className="italic">The Issue needs to be open to send a new message.</p>
            ) : (
              <NewMessage submit={newMessageSubmit} />
            )}
          </div>
          <div>
            <Messages messages={messages} />
          </div>
        </div>

      </div>
    </div>

    <div>
    {showEscalatePopup ?
      <div id="escalateModal" className="ModalDialog">
        <div className="ModalDialogBox">
          <div className="ModalHeader">
            <h1 className="ModalTitle">Escalate Issue # {issue.id}</h1>
            <a href="#close" title="Close" className="close">
              <i className="material-icons">cancel</i>
            </a>
          </div>
          <div className="ModalContent">
            <div className="bolder">No of escalations allowed: <span>{escalations.no_of_escalations}</span></div>
            <div className="bolder">Escalations left: <span>{escalations.escalations_left}</span></div>
            <div className="bolder">Next escalation awarded in: <span>{escalations.next_awarded_date}</span></div>
            {escalations.can_escalate ?
            <>
            <select
              value={reasonCat}
              onChange={(e) => setReasonCat(e.target.value)}
              >
              <option disabled selected value="">Please choose a reason to escalate...</option>
              <option value="QueryNotResolved">Query not yet resolved</option>
              <option value="FeedbackNotSatis">Feedback not satisfactory</option>
              <option value="IssuePastResolution">Issue past expected resolution date</option>
              <option value="ExpectedUrgencyChanged">Expected urgency has changed</option>
              <option value="Other">Other...</option>
            </select>

            {reasonCat === "Other" ?
              <textarea
                cols="5"
                rows="5"
                placeholder="Please provide a reason for escalation..."
                onChange={(e) => setReasonDesc(e.target.value)}
              />
            : null
            }
            <button className="EscalateSubmitBtn" onClick={escalateIssue} disabled={escalateStatus}>Escalate</button>
            </>
            : null
            }
            {escalateErrMsg !== "" ?
              <div className="EscalateErrMsg">{escalateErrMsg}</div>
            : null
            }
            {escalateSuccessMsg !== "" ?
              <div className="EscalateSuccessMsg">{escalateSuccessMsg}</div>
            : null
            }
          </div>
        </div>
      </div>
      : null}

    </div>
    </DesktopLayout>

    <MobileLayout>
      <div className={responsive.BackRow}>
        <button className={responsive.TertiaryBtn} onClick={GoToHome}>
          <i className="material-icons">arrow_back</i> Back to open tickets
        </button>
      </div>

      <div className={responsive.PageWrapper}>
        <div className={responsive.OverviewPanel}>
          <div className={responsive.OverviewFirstRow}>
            <p>[{issue.id}]</p>
            <div className={responsive.Priority}>{priorityAltText(issue.priority)}&nbsp;&nbsp;
              <div className="PriorityBack">
                <div className={["PriorityFront", priorityLevel(issue.priority).split("_")[1]].join(' ')}>&nbsp;</div>
              </div>
            </div>
          </div>

          <h1>{issue.name}</h1>

          <div>
            <a href="#escalateModal">
            {config ?
            <button className={responsive.EscalateBtn} onClick={PopupOpen}>
              <i className="material-icons">file_upload</i> Escalate Issue
            </button>
            :null
            }
            </a>
          </div>

          <div className={responsive.OverviewRow}>
            <i className="material-icons">outlined_flag</i>
            <span className={responsive.field}>Status:</span>
            <span className={responsive.value}>{issue.project_type}</span>
          </div>

          <div className={responsive.OverviewRow}>
            <i className="material-icons">folder_open</i>
            <span className={responsive.field}>Project:</span>
            <span className={responsive.value}>
              {issue.project_description ?
                <Tooltip title={issue.project_description}>
                  <span>{issue.project_name}</span>
                </Tooltip>
                : <span>{issue.project_name}</span>
              }
            </span>
          </div>

          <div className={responsive.OverviewRow}>
            <i className="material-icons">history</i>
            <span className={responsive.field}>Last action:</span>
            <span className={responsive.value}>{dateDisplay(issue.write_date)} (
            {new Date(issue.write_date).toLocaleString('en-GB')})</span>
          </div>

          <div className={responsive.OverviewRow}>
            <i className="material-icons">support_agent</i>
            <span className={responsive.field}>FCL:</span>
            <span className={responsive.value}>{issue.fcl_ref}</span>
          </div>

          <div className={responsive.OverviewRow}>
            <i className="material-icons">assignment_ind</i>
            <span className={responsive.field}>Assigned to:</span>
            <span className={responsive.value}>{issue.assigned_to ? issue.assigned_to : 'T.B.A.'}</span>
          </div>

          <div className={responsive.OverviewRow}>
            <i className="material-icons">code</i>
            <span className={responsive.field}>Development Stage:</span>
            <span className={responsive.value}>
              {issue.stage_description ?
              <Tooltip title={issue.stage_description}>
                <span>{issue.project_type}</span>
              </Tooltip>
              : 'T.B.A.'
              }
            </span>
          </div>

          <div className={responsive.OverviewRow}>
            <i className="material-icons">schedule_send</i>
            <span className={responsive.field}>Expected release date:</span>
            <span className={responsive.value}>
            {issue.sprint ?
              <span>{issue.sprint}</span>
            : 'T.B.A.' }
            </span>
          </div>

          <div className={responsive.OverviewRow}>
            <i className="material-icons">event</i>
            <div className={responsive.field}>Created date:</div>
            <div className={responsive.value}>{dateDisplay(issue.create_date)} (
            {new Date(issue.create_date).toLocaleString('en-GB')})</div>
          </div>

          <div className={responsive.OverviewRow}>
            <i className="material-icons">edit</i>
            <div className={responsive.field}>Created by:</div>
            <div className={responsive.value}>{issue.logger_name}</div>
          </div>

        </div>

        <div className={responsive.DetailsPanel}>
          <div className={responsive.DetailsFirstRow}>
            <div>
              <h2>Description</h2>
              <p dangerouslySetInnerHTML={{ __html: issue.description }}></p>
            </div>

            <div>
              <h2>Attachments</h2>
              { issue.attachments.length === 0 ? (
                <p className="italic">No attachments</p>
              ) : (
                <Attachments attachments={issue.attachments} />
              )}
            </div>
          </div>

          <div className={responsive.DetailsRow}>
            <h2>Messages</h2>
            <div>
              {issue.closed ? (
                <p className="italic">The Issue needs to be open to send a new message.</p>
              ) : (
                <NewMessage submit={newMessageSubmit} />
              )}
            </div>
            <div>
              <Messages messages={messages} />
            </div>
          </div>

        </div>
      </div>

      <div>
      {showEscalatePopup ?
        <div id="escalateModal" className="ModalDialog">
          <div className="ModalDialogBox">
            <div className="ModalHeader">
              <h1 className="ModalTitle">Escalate Issue # {issue.id}</h1>
              <a href="#close" title="Close" className="close">
                <i className="material-icons">cancel</i>
              </a>
            </div>
            <div className="ModalContent">
              <div className="bolder">No of escalations allowed: <span>{escalations.no_of_escalations}</span></div>
              <div className="bolder">Escalations left: <span>{escalations.escalations_left}</span></div>
              <div className="bolder">Next escalation awarded in: <span>{escalations.next_awarded_date}</span></div>
              {escalations.can_escalate ?
              <>
              <select
                value={reasonCat}
                onChange={(e) => setReasonCat(e.target.value)}
                >
                <option disabled selected value="">Please choose a reason to escalate...</option>
                <option value="QueryNotResolved">Query not yet resolved</option>
                <option value="FeedbackNotSatis">Feedback not satisfactory</option>
                <option value="IssuePastResolution">Issue past expected resolution date</option>
                <option value="ExpectedUrgencyChanged">Expected urgency has changed</option>
                <option value="Other">Other...</option>
              </select>

              {reasonCat === "Other" ?
                <textarea
                  cols="5"
                  rows="5"
                  placeholder="Please provide a reason for escalation..."
                  onChange={(e) => setReasonDesc(e.target.value)}
                />
              : null
              }
              <button className="EscalateSubmitBtn" onClick={escalateIssue} disabled={escalateStatus}>Escalate</button>
              </>
              : null
              }
              <div className="EscalateErrMsg">{escalateErrMsg}</div>
            </div>
          </div>
        </div>
        : null}

      </div>
    </MobileLayout>
    </>
  );
};

export default Issue;
