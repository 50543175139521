import './Issue.scss';

import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../context';
import { post, fetchData } from '../../api';


const EscalatePopup = ({ issue, currentId }) => {
    const { user } = useContext(UserContext);

    const [escalations, setEscalations] = useState([]);
    const [escalateStatus, setEscalateStatus] = useState(false);
    const [escalateErrMsg, setEscalateErrMsg] = useState("");
    const [escalateSuccessMsg, setEscalateSuccessMsg] = useState("");
    const [reasonCat, setReasonCat] = useState("");
    const [reasonDesc, setReasonDesc] = useState("");
    const [config, setConfig] = useState([]);
    const [showEscalatePopup, setShowEscalatePopup] = useState(false);
    const [issueData, setIssueData] = useState({});


    const issue_id = Object.keys(issue).filter((key) => key.includes("id")).reduce((obj, key) => {

        return Object.assign(obj, {
          [key]: currentId
        });
    }, {});
    
    const PopupOpen = () => {
        setEscalateStatus(false);
        setEscalateErrMsg("");
        setEscalateSuccessMsg("");
        setReasonCat("");
        setReasonDesc("");
        setEscalations(escalations);
    
        if (user !== null) {
          fetchData(`/get_available_escalations/${user.partner_id}`, setEscalations);
        }
    
        setShowEscalatePopup(true);
        setIssueData(issue_id);
    };


    const escalateIssue = async () => {
        setEscalateErrMsg("");
        setEscalateSuccessMsg("");
        const resp = await post('/escalate_issue', {
          reasonCat,
          user,
          issue,
          reasonDesc,
        });
        
        if (resp.ok && reasonCat !== "") {
          setEscalateSuccessMsg(<p>Your issue has been successfully escalated. <br /> We will be in touch with you soon.</p>);
          setEscalateStatus(true);
          await escalateToStage();
          fetchData(`/get_available_escalations/${user.partner_id}`, setEscalations);
          setReasonCat("");
        }
        else {
          if (reasonCat === "") {
            setEscalateErrMsg("Please select a reason to continue.");
          }
          let _resp = await resp.json();
          setEscalateErrMsg(_resp);
          return _resp;
        }
      };
    
    const escalateToStage = async () => {
        const resp = await post('/escalate_stage', {"issueId": currentId});
    
        if (resp.ok) {
          setEscalateSuccessMsg(<p>Your issue has been successfully escalated. <br /> We will be in touch with you soon.</p>);
        } else {
          setEscalateErrMsg("Unable to escalate your issue. Please contact us (help@goodx.co.za) for more information.", resp);
        }
        return resp.ok;
    };

    useEffect(() => {
        fetchData(`/odooConfig`, setConfig);
    }, [user]);

    return (
        <div>
            <a href="#escalateModal">
                {config ?
                <button className="EscalateBtn" onClick={PopupOpen}>
                    <i className="material-icons">file_upload</i> Escalate Issue
                </button>
                :null
                }
            </a>
            
            <div>
            {showEscalatePopup ?
            <div id="escalateModal" className="ModalDialog">
                <div className="ModalDialogBox">
                <div className="ModalHeader">
                    <h1 className="ModalTitle">Escalate Issue # {currentId}</h1>
                    <a href="#close" title="Close" className="close">
                    <i className="material-icons">cancel</i>
                    </a>
                </div>
                <div className="ModalContent">
                    <div className="bolder">No of escalations allowed: <span>{escalations.no_of_escalations}</span></div>
                    <div className="bolder">Escalations left: <span>{escalations.escalations_left}</span></div>
                    <div className="bolder">Next escalation awarded in: <span>{escalations.next_awarded_date}</span></div>
                    {escalations.can_escalate ?
                    <>
                    <select value={reasonCat} onChange={(e) => setReasonCat(e.target.value)}>
                        <option disabled selected value="">Please choose a reason to escalate...</option>
                        <option value="QueryNotResolved">Query not yet resolved</option>
                        <option value="FeedbackNotSatis">Feedback not satisfactory</option>
                        <option value="IssuePastResolution">Issue past expected resolution date</option>
                        <option value="ExpectedUrgencyChanged">Expected urgency has changed</option>
                        <option value="Other">Other...</option>
                    </select>

                    {reasonCat === "Other" ?
                    <textarea
                        cols="5"
                        rows="5"
                        placeholder="Please provide a reason for escalation..."
                        onChange={(e) => setReasonDesc(e.target.value)}
                    />
                    : null
                    }
                    <button className="EscalateSubmitBtn" onClick={escalateIssue} disabled={escalateStatus}>Escalate</button>
                    </>
                    : null
                    }
                    {escalateErrMsg !== "" ?
                    <div className="EscalateErrMsg">{escalateErrMsg}</div>
                    : null
                    }
                    {escalateSuccessMsg !== "" ?
                    <div className="EscalateSuccessMsg">{escalateSuccessMsg}</div>
                    : null
                    }
                </div>
                </div>
            </div>
            : null}
            </div>
      </div>
    );

}

export default EscalatePopup;