import {
  faFilePdf,
  faFileCsv,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDown,
  faSortAmountDownAlt,
  faSortNumericDown,
  faSortNumericDownAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Fuse from 'fuse.js';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { format as timeago } from 'timeago.js';

import { get } from '../../../api';
import { makeClass } from '../../../components/utils';
import { UserContext, ViewContext } from '../../../context';
import styles from './IssueList.module.scss';

import Tooltip from '@material-ui/core/Tooltip';

import DesktopLayout  from '../../../components/utils/DesktopLayout';
import MobileLayout  from '../../../components/utils/MobileLayout';
import responsive from '../../../responsive.module.scss';

import IssueSummary from '../../issue/IssueSummary';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const dateDisplay = (date) => (date ? timeago(date) : 'Never');

export const priorityLevel = (priority) => {
  switch (priority) {
    case 0:
      return styles.none;
    case 1:
      return styles.none;
    case 2:
      return styles.low;
    case 3:
      return styles.medium;
    case 4:
      return styles.high;
    case 5:
      return styles.critical;
    default:
      return styles.none;
  }
};

export const priorityAltText = (priority) => {
  switch (priority) {
    case 0:
      return 'Maintenance';
    case 1:
      return 'Development request';
    case 2:
      return 'Low';
    case 3:
      return 'Medium';
    case 4:
      return 'High';
    case 5:
      return 'Critical';
    default:
      return null;
  }
};

const IssueCard = ({ issue, onClick }) => (
  <>
  <DesktopLayout>
    <div className={styles.IssueCard} onClick={() => onClick(issue.id)} title="Click to view this ticket">
      <div className={styles.CardFirstRow}>
        <p>[{issue.id}]</p>

        <div className={styles.Priority}>
        {priorityAltText(issue.priority)}&nbsp;&nbsp;
          <div className={styles.PriorityBack}>
            <div className={[styles.PriorityFront, priorityLevel(issue.priority)].join(' ')}>&nbsp;</div>
          </div>
        </div>
      </div>


      {issue.notifications ?
        <h1 className={styles.NewNotifHeading}>{issue.name}<div className={styles.MsgIndicator}>{issue.notifications}</div></h1>
        : <h1>{issue.name}</h1>
      }


      <div className={styles.CardCol}>
        <div className={styles.CardRow}>
          <i className="material-icons">outlined_flag</i>
          <span>{issue.project_type}</span>
        </div>

        <div className={styles.CardRow}>
          <i className="material-icons">history</i>
          <span>{dateDisplay(issue.write_date)} (
          {new Date(issue.write_date).toLocaleString('en-GB')})</span>
        </div>

        <div className={styles.CardRow}>
          <i className="material-icons">folder_open</i>
          <span>
            {issue.project_description ?
              <Tooltip title={issue.project_description}>
                <span>{issue.project_name}</span>
              </Tooltip>
              : <span>{issue.project_name}</span>
            }
          </span>
        </div>

        <div className={styles.CardRow}>
          <i className="material-icons">schedule_send</i>
          <span>{issue.sprint}</span>
        </div>

      </div>

    </div>
  </DesktopLayout>

  <MobileLayout>
    <div className={responsive.IssueCard} onClick={() => onClick(issue.id)} title="Click to view this ticket">
      <div className={responsive.CardFirstRow}>
        <p>[{issue.id}]</p>

        <div className={responsive.Priority}>
        {priorityAltText(issue.priority)}&nbsp;&nbsp;
          <div className={styles.PriorityBack}>
            <div className={[styles.PriorityFront, priorityLevel(issue.priority)].join(' ')}>&nbsp;</div>
          </div>
        </div>
      </div>

      <h1>{issue.name}
      {issue.notifications ?
        <div className={styles.MsgIndicator}>{issue.notifications}</div>
        : null
      }
      </h1>

      <div className={responsive.CardCol}>
        <div className={responsive.CardRow}>
          <i className="material-icons">outlined_flag</i>
          <span>{issue.project_type}</span>
        </div>

        <div className={responsive.CardRow}>
          <i className="material-icons">history</i>
          <span>{dateDisplay(issue.write_date)} (
          {new Date(issue.write_date).toLocaleString('en-GB')})</span>
        </div>

        <div className={responsive.CardRow}>
          <i className="material-icons">folder_open</i>
          <span>
            {issue.project_description ?
              <Tooltip title={issue.project_description}>
                <span>{issue.project_name}</span>
              </Tooltip>
              : <span>{issue.project_name}</span>
            }
          </span>
        </div>

        <div className={responsive.CardRow}>
          <i className="material-icons">schedule_send</i>
          <span>{issue.sprint}</span>
        </div>

      </div>

    </div>
  </MobileLayout>
  </>
);

const IssuesCards = ({ issues, onClick, search }) => (
  <>
  <DesktopLayout>
    {issues ?
      <div className={styles.CardsWrapper}>
        {filterIssues({ issues, search })
        .map((issue) => (
          <IssueCard key={issue.id} issue={issue} onClick={onClick} />
        ))}
      </div>
    : <span>No tickets</span>}
  </DesktopLayout>

  <MobileLayout>
    <div className={responsive.CardsWrapper}>
      {filterIssues({ issues, search })
      .map((issue) => (
        <IssueCard key={issue.id} issue={issue} onClick={onClick} />
      ))}
    </div>
  </MobileLayout>
  </>
);

const IssueRow = ({ issue, onClick }) => (
  <div className={styles.RowWrapper}>
    <div className={styles.tableRow}>
      <div className={[styles.centered, styles.TableCell].join(' ')}>
        <Tooltip title={priorityAltText(issue.priority)}>
          <div onClick={() => onClick(issue.id)} className={styles.PriorityBack}>
            <div className={[styles.PriorityFront, priorityLevel(issue.priority)].join(' ')}>&nbsp;</div>
          </div>
        </Tooltip>
      </div>
    </div>

    <div className={styles.tableRow}>
      <div className={styles.TableCell} onClick={() => onClick(issue.id)}>{issue.id}</div>
    </div>
    <div className={styles.tableRow}>
      <div className={styles.TableCell} onClick={() => onClick(issue.id)}>
        {issue.notifications ?
          <span className={styles.bolder}>{issue.name}
          <div className={styles.MsgIndicator}>{issue.notifications}</div></span>
          : <span>{issue.name}</span>
        }
      </div>
    </div>

    <div className={styles.tableRow}>
    {issue.stage_description ?
      <Tooltip title={issue.stage_description}>
        <div className={styles.TableCell} onClick={() => onClick(issue.id)}>{issue.project_type}</div>
      </Tooltip>
    : <div className={styles.TableCell} onClick={() => onClick(issue.id)}>{issue.project_type}</div>
    }
    </div>

    <div className={styles.tableRow}>
      <div className={styles.TableCell} onClick={() => onClick(issue.id)}>{new Date(issue.write_date).toLocaleString('en-GB')}</div>
    </div>

    <div className={styles.tableRow}>
      <div className={styles.TableCell} onClick={() => onClick(issue.id)}>{issue.sprint}</div>
    </div>

  </div>
);

const IssuesTableHeader = ({
  name,
  displayName,
  sortIcons,
  sort,
  onClickSort,
}) => (
  <div onClick={() => onClickSort(name)} className={styles.IssuesTableHeader}>
    {displayName}&nbsp;
    {sort.col === name ? (
      sort.dir === 'dec' ? (
        <FontAwesomeIcon icon={sortIcons.dec} />
      ) : sort.dir === 'asc' ? (
        <FontAwesomeIcon icon={sortIcons.asc} />
      ) : null
    ) : null}
  </div>
);

const sortPrepare = (value) => {
  if (typeof value === 'string' || value instanceof String) {
    return value.toLowerCase();
  }

  return value || '';
};

const sortAsc = (col) => (a, b) => {
  a = sortPrepare(a[col]);
  b = sortPrepare(b[col]);
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};

const sortDec = (col) => (a, b) => {
  a = sortPrepare(a[col]);
  b = sortPrepare(b[col]);
  if (a < b) return 1;
  if (b < a) return -1;
  return 0;
};

const filterIssues = ({ issues, search }) => {
  const options = {
    shouldSort: true,
    threshold: 0.25,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [
      'assigned_to',
      'name',
      'description',
      'logger_name',
      'create_date',
      'id',
    ],
  };

  if (issues && search) {
    const fuse = new Fuse(issues, options);
    const results = fuse.search(search);
    const resultArray = [];
    for (let i = 0; i < results.length; i++) {
      resultArray.push(results[i].item);
    }
    return resultArray;
  } else {
    return issues || [];
  }
};

const IssuesTable = ({ issues, onClick, search, IssueId, history }) => {
  const [sort, setSort] = useState({ col: 'id', dir: 'dec' });
  const sortFn = sort.dir === 'dec' ? sortDec(sort.col) : sortAsc(sort.col);

  const setSortToggleDir = () =>
    setSort((s) => ({ col: s.col, dir: s.dir === 'dec' ? 'asc' : 'dec' }));

  const setSortColumn = (col) => setSort({ col, dir: 'dec' });

  const onClickSort = (sortColumn) => {
    if (sort.col === sortColumn) {
      setSortToggleDir();
    } else {
      setSortColumn(sortColumn);
    }
  };

  return (

    <div className={styles.TableWrapper}>
      <div className={styles.IssuesTable}>
        <IssuesTableHeader
          name="priority"
          displayName="Priority"
          sortIcons={{ asc: faSortAmountDownAlt, dec: faSortAmountDown }}
          sort={sort}
          onClickSort={onClickSort} />

        <IssuesTableHeader
          name="id"
          displayName="ID"
          sortIcons={{ asc: faSortNumericDown, dec: faSortNumericDownAlt }}
          sort={sort}
          onClickSort={onClickSort} />

        <IssuesTableHeader
          name="name"
          displayName="Subject"
          sortIcons={{ asc: faSortAlphaDown, dec: faSortAlphaDownAlt }}
          sort={sort}
          onClickSort={onClickSort} />

        <IssuesTableHeader
          name="project_type"
          displayName="Stage"
          sortIcons={{ asc: faSortAlphaDown, dec: faSortAlphaDownAlt }}
          sort={sort}
          onClickSort={onClickSort} />

        <IssuesTableHeader
          name="write_date"
          displayName="Last Action Date"
          sortIcons={{ asc: faSortAmountDown, dec: faSortAmountDownAlt }}
          sort={sort}
          onClickSort={onClickSort} />

        <IssuesTableHeader
          name="sprint"
          displayName="Exp. Release Date"
          sortIcons={{ asc: faSortAmountDown, dec: faSortAmountDownAlt }}
          sort={sort}
          onClickSort={onClickSort} />

        {!issues ?
          <div>No tickets</div>
        :
          <>
        {filterIssues({ issues, search })
          .sort(sortFn)
          .map((issue) => (
            <IssueRow key={issue.id} issue={issue} onClick={onClick} />
          ))} </>
        }

      </div>

      <div className={styles.IssueInspector}>
        {!IssueId ?
          <div className={styles.NoneSelected}>Click on a ticket on the left to view more information.</div>
        : null
        }
        {IssueId ?
          <IssueSummary className={styles.IssueSummary} issueId={IssueId} history={history} />
        : null
        }
      </div>
    </div>

  );
};

const OptionsToolbar = ({
  value: { view, setView, downloadIssues, downloadIssuesPDF, search, setSearch, heading }, }) => {
  return (
    <>
    <DesktopLayout>
      <div className={styles.OptionsToolbar}>
        <div className={styles.Heading}>
          {heading !== '/closed-tickets' ?
            <h1>Open Tickets</h1>
            :
            <h1>Closed Tickets</h1>
          }
        </div>

        <div className={styles.Search}>
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..." />
          <i className="material-icons">search</i>
        </div>

        <div className={styles.IconButtons}>
          <div
            className={makeClass(styles.ViewButton, view === 'table' ? 'active' : null)}
            title="List View"
            onClick={() => setView('table')} >
            <i className="material-icons">view_list</i> List View
          </div>
          <div
            className={makeClass(styles.ViewButton, view === 'cards' ? 'active' : null)}
            title="Card View"
            onClick={() => setView('cards')} >
            <i className="material-icons">apps</i> Grid View
          </div>
        </div>

        <div
          className={styles.DownloadBtn}
          title={
            heading !== '/closed-tickets' ?
              "Download Open Tickets to CSV"
              :
              "Download Closed Tickets to CSV"
            }
          onClick={downloadIssues} >
          <i className="material-icons">download</i>CSV
        </div>
        <div
          className={styles.DownloadBtn}
          title =
          {
          heading !== '/closed-tickets' ?
            "Download Open Tickets to PDF"
            :
            "Download Closed Tickets to PDF"
          }
          onClick={downloadIssuesPDF} >
          <i className="material-icons">download</i>PDF
        </div>
      </div>
    </DesktopLayout>
    <MobileLayout>
    <div className={responsive.IssueOptionsToolbar}>
      <div className={responsive.Heading}>
        {heading !== '/closed-tickets' ?
          <h1>Open Tickets</h1>
          :
          <h1>Closed Tickets</h1>
        }
      </div>

      <div className={responsive.Search}>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search..." />
        <i className="material-icons">search</i>
      </div>


      <div
        className={responsive.DownloadCsvBtn}
        title={
          heading !== '/closed-tickets' ?
            "Download Open Tickets to CSV"
            :
            "Download Closed Tickets to CSV"
          }
        onClick={downloadIssues} >
        <i><FontAwesomeIcon icon={faFileCsv} /></i>
      </div>
      <div
        className={responsive.DownloadPdfBtn}
        title =
        {
        heading !== '/closed-tickets' ?
          "Download Open Tickets to PDF"
          :
          "Download Closed Tickets to PDF"
        }
        onClick={downloadIssuesPDF} >
        <i><FontAwesomeIcon icon={faFilePdf} /></i>
      </div>

    </div>
    </MobileLayout>
    </>
  );
};

const IssueList = ({ history, ticketsStatus }) => {
  const { user } = useContext(UserContext);
  const { view, setView } = useContext(ViewContext);

  const [issues, setIssues] = useState([]);
  const [search, setSearch] = useState('');

  const [issueId, setIssueId] = useState('');

  const [heading, setHeading] = useState('');

  const downloadIssues = async () => {
    const IssuesFilter = filterIssues({ issues, search });
    
    const headers = [["ID", "Create Date", "Name", "Description", "Project Name", "Stage Name", "Email From", "FCL", "FAX", "Phone", "Mobile", 
                  "Last Action", "Task ID", "Sprint", "Date Closed"]];
    const data = IssuesFilter.map(issue =>
      [issue.id, new Date(issue.create_date).toISOString().split('T')[0], issue.name, issue.description, issue.project_name, issue.project_type,
      issue.email_from, issue.assigned_to, issue.fax_no, issue.mobile_no, issue.tel_no, issue.write_date, issue.task_id, issue.sprint, issue.date_closed]);
    const csvRows = [];
    csvRows.push(headers.join(','));
    data.map(data => csvRows.push(Object.values(data).join(',')));
    let csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "GoodX_Client_Portal_Tickets.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadIssuesPDF = async () => {
    const IssuesFilter = filterIssues({ issues, search });
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title =
      heading !== '/closed-tickets' ?
      "Client Portal - Open Tickets"
      :
      "Client Portal - Closed Tickets"
    ;
    const headers = [["ID", "Create Date", "Subject", "Stage", "Created By"]];

    const data = IssuesFilter.map(issue =>
      [issue.id, new Date(issue.create_date).toISOString().split('T')[0],
      issue.name, issue.project_type, issue.logger_name
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    for(var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text('Page ' + String(i) + ' of ' + String(pageCount),450,570,null,null,"right");
    }

    doc.save("GoodX_Client_Portal_Tickets.pdf")
  };

  useEffect(() => {
    setHeading(history.location.pathname);
    const fetchIssues = async () => {
      const resp = await get(`/issue/${ticketsStatus}`);

      if (resp.ok) {
        setIssues(await resp.json());
      }
    };

    fetchIssues();
  }, [ticketsStatus, setIssues, history.location.pathname]);

  const clickHandler = (issueId) =>
    history.push({ pathname: `/issue/${issueId}` });

  const clickHandlerSummary = (issueId) =>
    setIssueId(issueId);

  const View =
    view === 'table' ? (
      <>
      <DesktopLayout>
        <IssuesTable issues={issues} onClick={clickHandlerSummary} search={search} IssueId={issueId} history={history} />
      </DesktopLayout>
      <MobileLayout>
        <IssuesCards issues={issues} onClick={clickHandler} search={search} />
      </MobileLayout>
      </>
    ) : (
      <>
      <DesktopLayout>
        <IssuesCards issues={issues} onClick={clickHandler} search={search} />
      </DesktopLayout>
      <MobileLayout>
        <IssuesCards issues={issues} onClick={clickHandler} search={search} />
      </MobileLayout>
      </>
    );

  return (
    <React.Fragment>
      <OptionsToolbar
        value={{ view, setView, downloadIssues, downloadIssuesPDF, search, setSearch, heading }}
      />
      {View}
    </React.Fragment>
  );
};

export default withRouter(IssueList);
export { dateDisplay };
