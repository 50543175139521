import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { post } from '../../api';

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import styles from './Navbar.module.scss';

import DesktopLayout  from '../utils/DesktopLayout';
import MobileLayout  from '../utils/MobileLayout';
import responsive from '../../responsive.module.scss';

const SelectUser = ({ user, onSwitchUser }) => (
  <select
    value={user.id}
    onChange={(e) => onSwitchUser(e.target.value)}
    className={styles.MenuSelect}
  >
    {user.commertial_partners
      .sort((a, b) => a.full_name.localeCompare(b.full_name))
      .map((u) => (
        <option key={u.user_id} value={u.user_id}>
          {u.full_name}
        </option>
      ))}
  </select>
);

const Navbar = ({ user, onLogout, onSwitchUser, tokenValid }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dropDownOpen = Boolean(anchorEl);
  const [notifications, setNotifications] = useState('');
  const [isFinancial, setIsFinancial] = useState(false);
  const [isInformationOfficer, setIsInformationOfficer] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setAnchorEl(null);
    if (user !== null) {
    const fetchNotifications = async () => {
      const resp = await post('/notifications', { user });

      if (resp.ok) {
        setNotifications(await resp.json());
      }

      return resp.ok;
    };

    fetchNotifications();
  }
  }, [setNotifications, user]);

  useEffect(() => {
    if (user !== null) {
      const fetchData = async () => {
        const auth = await post(`/check_auth`, user);
  
        if (auth.ok) {
          setIsFinancial(await auth.json())
        }
      };
  
      fetchData();

      const fetchInformationOfficer = async () => {
        const auth = await post(`/check_is_information_officer`, user);
  
        if (auth.ok) {
          setIsInformationOfficer(await auth.json())
        }
      };
  
      fetchInformationOfficer();
    const fetchNewNotifications = setInterval(async () => {
      const resp = await post('/notifications', { user });

      if (resp.ok) {
        setNotifications(await resp.json());
      }
    },120000);
    return () => clearInterval(fetchNewNotifications);
    }
  }, [notifications, user, setIsFinancial, setIsInformationOfficer]);

  if (!tokenValid) {
    return null;
  }

  return (
    <>
    <DesktopLayout>
  		<div className={styles.Nav}>
        <div className={styles.NavLogo}>
  				<img src="/ClientPortalLogo.svg" alt="Client Portal Logo" />
  			</div>
  			<div className={styles.NavLinks}>
          <NavLink activeClassName={styles.active} exact to="/log-ticket">
            <i className="material-icons">add_circle</i>Log New Ticket
          </NavLink>
          <NavLink activeClassName={styles.active} exact to="/">
            <i className="material-icons">help</i>Open Tickets
            {notifications ? <span className={styles.MsgIndicator}>{notifications}</span> : null}
          </NavLink>
          {isFinancial ? 
          <NavLink activeClassName={styles.active} exact to="/invoices">
            <i className="material-icons">account_balance_wallet</i>Account info
          </NavLink>:
          <></>
          }
          <NavLink activeClassName={styles.active} exact to="/closed-tickets">
            <i className="material-icons">check_circle</i>Closed Tickets
  				</NavLink>
          <NavLink activeClassName={styles.active} exact to="/support-stats">
            <i className="material-icons">account_balance_wallet</i>Support Stats
          </NavLink>
          <NavLink activeClassName={styles.active} exact to="/Releases">
            <i className="material-icons">new_releases</i>Latest Releases
  				</NavLink>
          <NavLink activeClassName={styles.active} to="#"
            onClick={() => window.open("https://learning.goodx.co.za/mod/page/view.php?id=3523", "_blank")}
          >
            <i className="material-icons">info</i>Help & Library&nbsp;<i className="material-icons HelpNav">open_in_new</i>
  				</NavLink>
        </div>

        <div className={styles.MoreMenuBtn}>
          <MoreVertIcon onClick={handleClick} />
          {dropDownOpen ?
          <Menu
            anchorEl={anchorEl}
            keepMounted onClose={handleClose}
            open={dropDownOpen}
            >
            <div className={styles.NavContainer}>
              <ul>
                <li>
                  {user ? <SelectUser user={user} onSwitchUser={onSwitchUser} /> : null}
                </li>
                {isInformationOfficer ?
                  <React.Fragment>
                    <li>
                      <NavLink activeClassName={styles.active} exact to="/Info-Update-Requests">
                        <i className="material-icons" >bar_chart</i>Update Information Request
                      </NavLink>
                    </li>
                  </React.Fragment>:
                  <></>
                }
                {user && !user.customer ?
                <React.Fragment>
                  <li>
                    <NavLink activeClassName={styles.active} to="/survey">
                      <i className="material-icons">bar_chart</i> Survey Results
                    </NavLink>
                  </li>
                </React.Fragment>
                :null
                }
                <li>
                  <a className={styles.LogOutBtn} href="/" onClick={onLogout}><i className="material-icons">logout</i> Logout</a>
                </li>
              </ul>
            </div>
          </Menu>
          : null
          }

        </div>

      </div>
    </DesktopLayout>

    <MobileLayout>
      <div className={responsive.Nav}>
        <div className={responsive.NavLogo}>
          <img src="/ClientPortalIcon.svg" alt="Client Portal Logo" />
        </div>
        <div className={responsive.NavLinks}>
          <NavLink activeClassName={responsive.active} exact to="/log-ticket">
            <i className="material-icons">add_circle</i>
          </NavLink>
          {/* {isFinancial ? 
          <NavLink activeClassName={styles.active} exact to="/invoices">
            <i className="material-icons">account_balance_wallet</i>
          </NavLink>:
          <></>
          } */}
          <NavLink activeClassName={responsive.active} className={responsive.WithIndicator}  exact to="/">
            <i className="material-icons">help</i>
            {notifications ? <span className={responsive.MsgIndicator}></span> : null}
          </NavLink>
          <NavLink activeClassName={responsive.active} exact to="/closed-tickets">
            <i className="material-icons">check_circle</i>
  				</NavLink>
          <NavLink activeClassName={responsive.active} to="#"
            onClick={() => window.open("https://learning.goodx.co.za/mod/page/view.php?id=3523", "_blank")}
          >
            <i className="material-icons">info</i>
  				</NavLink>

        </div>
        <div className={responsive.MoreMenuBtn}>
          <MoreVertIcon onClick={handleClick} />
          {dropDownOpen ?
          <Menu
            anchorEl={anchorEl}
            keepMounted onClose={handleClose}
            open={dropDownOpen}
            >
            <div className={styles.NavContainer}>
              <ul>
                <li>
                  {user ? <SelectUser user={user} onSwitchUser={onSwitchUser} /> : null}
                </li>
                {user && !user.customer ?
                <React.Fragment>
                  <li>
                    <NavLink activeClassName={styles.active} to="/survey">
                      <i className="material-icons">bar_chart</i> Survey Results
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName={styles.active} exact to="/support">
                      <i className="material-icons">support</i> Support
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName={styles.active} exact to="/repo-overview">
                      <i className="material-icons">source</i> Repo Overview
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName={styles.active} exact to="/repo-overview-internal">
                      <i className="material-icons">integration_instructions</i> Internal Repo Overview
                    </NavLink>
                  </li>
                </React.Fragment>
                :null
                }
                <li>
                  <a className={styles.LogOutBtn} href="#" onClick={onLogout}><i className="material-icons">logout</i> Logout</a>
                </li>
              </ul>
            </div>
          </Menu>
          : null
          }
        </div>
      </div>
    </MobileLayout>
    </>
  );
};

export default Navbar;
